import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { PdfViewerProps, PdfjsField } from './PdfViewer.model'
import { base64ToBlobUrl } from '../PdfViewerLegacy/pdf.utils'
import WebViewer from '@pdftron/pdfjs-express'
import styles from './PdfViewer.module.scss'
import classNames from 'classnames'
import { PDFJS_EXPRESS_KEY } from '../../../../environment/pdfjsExpress'

const CUSTOM_EVENT_FIELD_CHANGED = 'CUSTOM_EVENT_FIELD_CHANGED'
const CUSTOM_EVENT_TEXT_WIDGET_FOCUS = 'CUSTOM_EVENT_TEXT_WIDGET_FOCUS'
const CUSTOM_EVENT_TEXT_WIDGET_DBCLICK = 'CUSTOM_EVENT_TEXT_WIDGET_DBCLICK'

export const PdfViewer: FC<PdfViewerProps> = ({
  fileContent,
  fileUrl,
  fileName,
  fieldValues,
  readOnly,
  onExitFullScreen,
  onEnterFullScreen,
  onDbClick,
  onChange,
  onFocusInput,
}) => {
  const isFirstRender = useRef(true)
  const [webViewerInstance, setWebViewerInstance] = useState<null | any>(null)
  const [isAnnotationsLoaded, setAnnotationsLoaded] = useState(false)
  const viewer = useRef(null)

  const handleDbClick = useCallback(() => {
    onDbClick && !readOnly && onDbClick()
  }, [onDbClick, readOnly])

  const handleFocus = useCallback(
    (event: CustomEvent) => {
      const oldFieldMatch = event.detail.name?.match(/#(.*)#$/)

      onFocusInput &&
        onFocusInput({
          id: oldFieldMatch && oldFieldMatch[1] ? oldFieldMatch[1] : event.detail.name,
          name: event.detail.name,
        })
    },
    [onFocusInput],
  )

  const handleFieldChanged = useCallback(
    (event: CustomEvent) => {
      if (onChange && !readOnly) {
        const oldFieldMatch = event.detail.field.name?.match(/#(.*)#$/)
        const fieldValue = {
          id: oldFieldMatch && oldFieldMatch[1] ? oldFieldMatch[1] : event.detail.field.name,
          name: event.detail.field.name,
          value: event.detail.value,
          type: 'value',
        }
        onChange(fieldValue)
      }
    },
    [onChange, readOnly],
  )

  const onToggleFullscreen = useCallback(
    (e) => {
      if (e.detail.isInFullscreen) {
        webViewerInstance.UI.setFitMode(webViewerInstance.FitMode.FitPage)
        onEnterFullScreen && onEnterFullScreen()
      } else {
        webViewerInstance.UI.setFitMode(webViewerInstance.FitMode.FitWidth)
      }
    },
    [onEnterFullScreen, webViewerInstance],
  )

  const getFileUrl = useCallback(() => {
    if (fileContent) {
      return base64ToBlobUrl(fileContent)
    }

    if (fileUrl) {
      return fileUrl
    }

    throw new Error('You must provide "fileContent" or "fileUrl"')
  }, [fileContent, fileUrl])

  const handleExitFullScreen = useCallback(() => {
    if (!document.fullscreenElement && onExitFullScreen) onExitFullScreen()
  }, [onExitFullScreen])

  // Ajout d'un listener sur l'event custom de focus des champs du PDF
  useEffect(() => {
    document.body.addEventListener(CUSTOM_EVENT_TEXT_WIDGET_FOCUS, handleFocus as EventListener)
    return () =>
      document.body.removeEventListener(
        CUSTOM_EVENT_TEXT_WIDGET_FOCUS,
        handleFocus as EventListener,
      )
  }, [handleFocus])

  // Ajout d'un listener sur l'event custom de double clic des champs du PDF
  useEffect(() => {
    document.body.addEventListener(CUSTOM_EVENT_TEXT_WIDGET_DBCLICK, handleDbClick as EventListener)
    return () =>
      document.body.removeEventListener(
        CUSTOM_EVENT_TEXT_WIDGET_DBCLICK,
        handleDbClick as EventListener,
      )
  }, [handleDbClick])

  // Ajout d'un listener sur l'event custom de modification des champs du PDF
  useEffect(() => {
    document.body.addEventListener(CUSTOM_EVENT_FIELD_CHANGED, handleFieldChanged as EventListener)
    return () =>
      document.body.removeEventListener(
        CUSTOM_EVENT_FIELD_CHANGED,
        handleFieldChanged as EventListener,
      )
  }, [handleFieldChanged])

  // Ajout d'un listener sur l'event de toggle du mode fullscreen
  useEffect(() => {
    if (webViewerInstance) {
      webViewerInstance.UI.addEventListener(
        webViewerInstance.UI.Events.FULLSCREEN_MODE_TOGGLED,
        onToggleFullscreen,
      )
      return () =>
        webViewerInstance.UI.removeEventListener(
          webViewerInstance.UI.Events.FULLSCREEN_MODE_TOGGLED,
          onToggleFullscreen,
        )
    }
  }, [onToggleFullscreen, webViewerInstance])

  const onInitWebViewer = (instance) => {
    const { UI, Core, FitMode } = instance

    UI.disableFeatures([UI.Feature.MouseWheelZoom])
    UI.disableFeatures([UI.Feature.Print])
    UI.setLayoutMode(UI.LayoutMode.Continuous)
    UI.setFitMode(FitMode.FitWidth)
    UI.setLanguage('fr')
    UI.setHeaderItems((header) => {
      header.getHeader('default').push({
        img: 'icon-header-full-screen',
        index: -1,
        type: 'actionButton',
        element: 'fullScreenButton',
        onClick: () => {
          UI.toggleFullScreen()
        },
      })
    })

    Core.documentViewer.addEventListener('annotationsLoaded', () => setAnnotationsLoaded(true))

    Core.annotationManager.enableReadOnlyMode()

    Core.annotationManager.addEventListener('fieldChanged', (field: PdfjsField, value: string) =>
      document.body.dispatchEvent(
        new CustomEvent(CUSTOM_EVENT_FIELD_CHANGED, {
          detail: { field, value },
        }),
      ),
    )

    Core.Annotations.setCustomCreateInnerElementHandler(
      Core.Annotations.TextWidgetAnnotation,
      function (annotationManager, { annotation, originalCreateInnerElement }) {
        const el = originalCreateInnerElement()
        el.addEventListener('focus', () =>
          document.body.dispatchEvent(
            new CustomEvent(CUSTOM_EVENT_TEXT_WIDGET_FOCUS, {
              detail: { name: annotation.fieldName },
            }),
          ),
        )

        el.addEventListener('dblclick', () =>
          document.body.dispatchEvent(new CustomEvent(CUSTOM_EVENT_TEXT_WIDGET_DBCLICK)),
        )

        return el
      },
    )
  }

  // Application du style après chargement du PDF ou à la modification de la props readOnly
  useEffect(() => {
    if (webViewerInstance) {
      const { Annotations } = webViewerInstance.Core

      Annotations.WidgetAnnotation.getCustomStyles = (widget) => {
        const commonStyle = {
          color: 'rgb(47, 47, 47)',
          'font-family': 'sans-serif',
          'pointer-events': readOnly ? 'none' : 'auto',
        }
        if (widget instanceof Annotations.TextWidgetAnnotation) {
          return {
            ...commonStyle,
            'background-color': readOnly ? 'none' : '#0036ff21',
            cursor: readOnly ? 'default' : 'text',
            'scrollbar-width': 'none',
            'padding-top': 0,
          }
        }
        if (
          widget instanceof Annotations.CheckButtonWidgetAnnotation ||
          widget instanceof Annotations.RadioButtonWidgetAnnotation
        ) {
          return {
            ...commonStyle,
            'background-color': readOnly ? 'white' : '#dee4ff',
            cursor: readOnly ? 'default' : 'pointer',
            'border-radius': 0,
          }
        }
      }
    }
  }, [webViewerInstance, readOnly])

  // Modification des champs en readOnly (ou non) après leur chargement
  useEffect(() => {
    if (webViewerInstance && isAnnotationsLoaded) {
      webViewerInstance.Core.annotationManager.getAnnotationsList().forEach((annot) => {
        if (annot instanceof webViewerInstance.Core.Annotations.TextWidgetAnnotation) {
          annot.fieldFlags.set('ReadOnly', readOnly)
        }
      })
    }
  }, [isAnnotationsLoaded, webViewerInstance, readOnly])

  // Chargement d'un PDF dans l'instance de pdfjs-express, permet de ne monter qu'une seule instance
  useEffect(() => {
    if (webViewerInstance) {
      webViewerInstance.UI.loadDocument(getFileUrl(), {
        filename: fileName,
        extension: 'pdf',
      })
    }
  }, [fileContent, fileName, fileUrl, getFileUrl, webViewerInstance])

  const getTerminalFields = useCallback((fields: PdfjsField[]): PdfjsField[] => {
    return fields.reduce<PdfjsField[]>((acc, field) => {
      if (field.isTerminal()) {
        return [...acc, field]
      }
      return [...acc, ...getTerminalFields(field.children)]
    }, [])
  }, [])

  // Ajout des valeurs dans les champs du PDF une fois chargés
  useEffect(() => {
    if (webViewerInstance && isAnnotationsLoaded) {
      const rootFields: PdfjsField[] = []
      webViewerInstance.Core.annotationManager
        .getFieldManager()
        .forEachField((field: PdfjsField) => rootFields.push(field))

      document.body.removeEventListener(
        CUSTOM_EVENT_FIELD_CHANGED,
        handleFieldChanged as EventListener,
      )

      fieldValues?.forEach((fieldValue) => {
        const match = fieldValue.name?.match(/^#PARTIAL#(#.*#)$/)

        getTerminalFields(rootFields).forEach((field) => {
          if (match && field.name.match(`${match[1]}$`)) {
            field.setValue(fieldValue.value)
          } else if (fieldValue.name === field.name) {
            field.setValue(fieldValue.value)
          }
        })
      })

      document.body.addEventListener(
        CUSTOM_EVENT_FIELD_CHANGED,
        handleFieldChanged as EventListener,
      )
    }
  }, [isAnnotationsLoaded, fieldValues, webViewerInstance, getTerminalFields, handleFieldChanged])

  useEffect(() => {
    if (handleExitFullScreen) {
      document.addEventListener('fullscreenchange', handleExitFullScreen)

      return () => document.removeEventListener('fullscreenchange', handleExitFullScreen)
    }
  }, [handleExitFullScreen])

  // Instanciation de pdfjs-express
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      WebViewer(
        {
          licenseKey: PDFJS_EXPRESS_KEY,
          path: '/app/pdfjs-express',
          disabledElements: [
            'toolbarGroup-View',
            'toggleNotesButton',
            'menuButton',
            'selectToolButton',
            'panToolButton',
            'viewControlsButton',
            'outlinesPanelButton',
          ],
        },
        viewer.current,
      ).then((instance: any) => {
        onInitWebViewer(instance)
        setWebViewerInstance(instance)
      })
    }
  }, [])

  return <div className={classNames(styles.pdfViewer)} ref={viewer}></div>
}
