import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { FileViewer } from '../../../file'
import { mapFieldValues } from '../../../file/FileViewer/PdfViewerLegacy/pdf.utils'
import { FieldValue } from '../../../../model/Document'
import { FileInstanceContentProps } from './FileInstanceContent.model'
import styles from './FileInstanceContent.module.scss'
import { isDefined } from '../../../../misc/functions.utilities'
import { FocusedInput } from '../../../file/FileViewer/FileViewer.model'
import { uniqBy } from 'lodash'
import { useDebounce } from 'react-use'

export const FileInstanceContent: FunctionComponent<FileInstanceContentProps> = ({
  documentInstance,
  medicalEvent,
  variablesData,
  setVariableDataLoading,
  getVariableData,
  searchBaseVariable,
  updateFileInstance,
}) => {
  const [focusedInput, setFocusedInput] = useState<FocusedInput | null>(null)
  const [lastFieldValues, setLastFieldValues] = useState<FieldValue[]>()

  useEffect(() => {
    getVariableData()
  }, [getVariableData])

  useDebounce(
    () => {
      if (lastFieldValues) {
        setVariableDataLoading(true)
        updateFileInstance(documentInstance.id, { type: 'pdf', fieldValues: lastFieldValues })
      }
    },
    500,
    [documentInstance.id, lastFieldValues, setVariableDataLoading, updateFileInstance],
  )

  const fieldValues = useMemo<Array<FieldValue>>(
    () => lastFieldValues || documentInstance.fieldValues,
    [lastFieldValues, documentInstance.fieldValues],
  )

  const resolvedFieldValues = useMemo<Array<FieldValue> | null>(
    () => (isDefined(variablesData) ? mapFieldValues(fieldValues, variablesData) : null),
    [fieldValues, variablesData],
  )

  const handleOnChange = useCallback(
    (newFieldValue: FieldValue) => {
      if (documentInstance) {
        const newFieldValues = uniqBy<FieldValue>(fieldValues, 'name')
        const newFieldValueIndex = newFieldValues.findIndex(
          (value) => value.name === newFieldValue.name,
        )

        if (newFieldValueIndex !== -1) {
          if (newFieldValue.value !== '') {
            newFieldValues[newFieldValueIndex] = newFieldValue
          } else {
            newFieldValues.splice(newFieldValueIndex, 1)
          }
        } else {
          newFieldValues.push(newFieldValue)
        }

        setLastFieldValues(newFieldValues)
      }
    },
    [documentInstance, fieldValues],
  )

  return (
    <div className={styles.fileViewer}>
      {resolvedFieldValues && (
        <FileViewer
          file={documentInstance.file}
          fieldValues={resolvedFieldValues}
          isInteractive={true}
          readOnly={!medicalEvent?.isEditable}
          onChange={handleOnChange}
          contentType={documentInstance.contentType}
          enableScrollButton
          onInsertVariable={() => {
            searchBaseVariable(
              (id) =>
                focusedInput &&
                handleOnChange({
                  id: focusedInput.id,
                  type: 'variable',
                  value: id,
                  name: focusedInput.name,
                }),
              'fileTemplate',
            )
          }}
          onFocusInput={(input) => setFocusedInput(input)}
        />
      )}
    </div>
  )
}
