import { useCallback, useState } from 'react'
import { QuestionTemplate } from '../../../../model/Questionnaire'
import { isDefined } from '../../../../misc/functions.utilities'
import { isEqual } from 'lodash'
import { BottomPanel } from '../BottomPanel/BottomPanel.component'
import { QuestionItem } from '../../../questionnaire'
import createSearchPanel, { FiltersType } from '../../../../containers/SearchPanel'
import { QuestionQueryFilters, SearchQuestionBottomPanelProps } from './SearchQuestionBottomPanel.model'
import { useGetInfiniteQuestionTemplates } from '../../../../hooks/queries/questionTemplates'

const SearchPanel = createSearchPanel<QuestionTemplate>()

export const SearchQuestionBottomPanel = ({
  isUnmounting,
  onRequestClose,
  onSelect,
}: SearchQuestionBottomPanelProps) => {
  const [queryFilters, setQueryFilters] = useState<QuestionQueryFilters>({})
  const {
    query: { isLoading, fetchNextPage },
    questionList: items,
    paginationState,
    cancelPendingQuery,
  } = useGetInfiniteQuestionTemplates({ filters: queryFilters })

  const onPrimaryAction = (selectedItem?: QuestionTemplate) => {
    if (selectedItem) {
      onSelect(selectedItem)
    }
  }

  const handleFiltersChange = useCallback(
    (filters) => {
      const newQueryFilters: QuestionQueryFilters = {
        ...(filters.search && { search: filters.search }),
        ...(isDefined(filters.private) && { private: filters.private }),
      }

      if (!isEqual(queryFilters, newQueryFilters)) {
        cancelPendingQuery()
        setQueryFilters(newQueryFilters)
      }
    },
    [cancelPendingQuery, queryFilters],
  )

  return (
    <BottomPanel
      title={'Ajouter une question existante'}
      isUnmounting={isUnmounting}
      onRequestClose={onRequestClose}
    >
      <SearchPanel
        items={items}
        loading={isLoading}
        itemCount={paginationState?.itemCount ?? null}
        pageCount={paginationState?.pageCount ?? null}
        currentPage={paginationState?.currentPage ?? 0}
        onPrimaryAction={onPrimaryAction}
        getItems={() => fetchNextPage()}
        onFiltersChange={handleFiltersChange}
        renderActions={() => null}
        renderItem={(item) => <QuestionItem item={item} />}
        filterCapabilities={[FiltersType.Title, FiltersType.Visibility]}
      />
    </BottomPanel>
  )
}
