import { takeEvery, put, select, all, call } from 'redux-saga/effects'
import { filesDomainActions } from './files.actions'
import { FilesDomainActionTypes } from './files.model'
import { addInfo } from '../../message/index'
import { createFileByIdSelector } from './files.selectors'
import { downloadBlob } from '../../../misc/blob.utilities'
import { CustomFile } from '../../../model/File'
import {
  base64ToBlobUrl,
  pdfDataToUrl,
} from '../../../components/file/FileViewer/PdfViewerLegacy/pdf.utils'
import { getDocument } from 'pdfjs-dist'
import { requestPrintByBlobUrl } from '../../../misc/print.utilities'
import { fetchFile, fileToBlob } from '../../cache/files/api'
import { ApiResponse } from 'apisauce'
import { domainMailActions } from '../mail'

function* downloadFileWorker({ fileId }: ReturnType<typeof filesDomainActions.download>) {
  yield put(
    addInfo('Téléchargement demandé', 'Votre fichier sera disponible dans quelques instants'),
  )
  const fileByIdSelector = createFileByIdSelector(fileId)
  const file: CustomFile | null = yield select(fileByIdSelector)
  if (file && file.base64) {
    file.visibleName.endsWith(file.extension)
    fetch(file.base64)
      .then((res) => res.blob())
      .then((blob) => downloadBlob(blob, `${file.visibleName}.${file.extension}`))
  }
}

function* downloadFileWatcher() {
  yield takeEvery(FilesDomainActionTypes.DOWNLOAD, downloadFileWorker)
}

function* selectAndPrintPdfFileWorker({
  fileId,
}: ReturnType<typeof filesDomainActions.selectAndPrint>) {
  const fileByIdSelector = createFileByIdSelector(fileId)
  const file = yield select(fileByIdSelector)
  if (file && file.base64) {
    yield put(filesDomainActions.print(file))
  }
}

function* selectAndPrintPdfFileWatcher() {
  yield takeEvery(FilesDomainActionTypes.SELECT_AND_PRINT, selectAndPrintPdfFileWorker)
}

function* printPdfFileWorker({ file }: ReturnType<typeof filesDomainActions.print>) {
  yield put(addInfo('Impression demandée', 'Veuillez patienter quelques instants'))
  if (file.base64) {
    getDocument({
      url: base64ToBlobUrl(file.base64),
    }).promise.then((pdfDocument) => {
      pdfDocument.getData().then((pdfData) => {
        const blobUrl = pdfDataToUrl(pdfData)
        requestPrintByBlobUrl(blobUrl)
      })
    }) 
  }
}

function* printPdfFileWatcher() {
  yield takeEvery(FilesDomainActionTypes.PRINT, printPdfFileWorker)
}

function* sendPdfFileWorker({ fileIds }: ReturnType<typeof filesDomainActions.send>) {
  const results: ApiResponse<CustomFile>[] = yield all(
    fileIds.map((fileId) => call(fetchFile, fileId)),
  )
  const files: File[] = yield all(
    results.map(({ data: file }) => (file ? call(fileToBlob, file) : undefined)),
  )
  yield put(domainMailActions.setFilesToSend({ files }))
}

function* sendPdfFileWatcher() {
  yield takeEvery(FilesDomainActionTypes.SEND, sendPdfFileWorker)
}

export const filesDomainSagas = {
  downloadFileWatcher,
  selectAndPrintPdfFileWatcher,
  printPdfFileWatcher,
  sendPdfFileWatcher,
}
