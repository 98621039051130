import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { deleteMedicalEvent } from '../../../data/medicalEvents'
import { medicalEventsKeys } from './medicalEvents.keys'
import { patientStatisticsKeys } from '../patientStatistics/patientStatistics.keys'
import { patientImportantInformationsKeys } from '../patientImportantInformations/patientImportantInformations.keys'

export interface DeleteMedicalEventMutateVariable {
  medicalEventId: number
  patientId: number
}

export const useDeleteMedicalEvent = () => {
  const queryClient = useQueryClient()

  return useMutation<null, Error, DeleteMedicalEventMutateVariable>(
    async ({ medicalEventId }) => {
      const { ok } = await deleteMedicalEvent(medicalEventId)
      if (!ok) {
        throw new UserFriendlyError('error', `La suppression de l'évènement médical a échoué`)
      }
      return null
    },
    {
      onSuccess: (_data, { patientId }) => {
        queryClient.invalidateQueries(medicalEventsKeys.lists)
        queryClient.invalidateQueries(patientStatisticsKeys.detail(patientId))
        queryClient.invalidateQueries(patientImportantInformationsKeys.listAll(patientId))
      },
    },
  )
}
