import { useMutation, useQueryClient } from '@tanstack/react-query'
import { mergePatients } from '../../../data/patients'
import { UserFriendlyError } from '../../../model/Error'
import { patientsKeys } from './patients.keys'
import { medicalEventsKeys } from '../medicalEvents/medicalEvents.keys'

interface mergeVariable {
  patientId: number
  patientToMergeId: number
}

export const useMergePatient = () => {
  const queryClient = useQueryClient()

  return useMutation<null, Error, mergeVariable>(
    async ({ patientId, patientToMergeId }) => {
      const { ok } = await mergePatients(patientId, patientToMergeId)
      if (!ok) {
        throw new UserFriendlyError('error', 'La fusion des patients a échoué')
      }
      return null
    },
    {
      onSuccess: (_data, { patientId }) => {
        queryClient.invalidateQueries(medicalEventsKeys.lists)
        queryClient.invalidateQueries(patientsKeys.lists)
        queryClient.invalidateQueries(patientsKeys.detail(patientId))
      },
    },
  )
}
