import { DocumentInstance, FarteDocumentInstance } from '../../../model/DocumentInstance'
import { LightQuestionnaire } from '../../../model/Questionnaire'

import { DomainDocumentInstancesActionTypes } from './documentInstances.model'
import { IdentityIdentifier } from '../../restux/restux.model'
import { EditorValue } from '@follow/farte'

export const domainDocumentInstancesActions = {
  addQuestionnaire: (
    documentInstance: FarteDocumentInstance,
    questionnaires: LightQuestionnaire[],
  ) => ({
    type: DomainDocumentInstancesActionTypes.ADD_QUESTIONNAIRE,
    documentInstance,
    questionnaires,
  }),
  removeVariable: (documentInstance: FarteDocumentInstance, variableId: string) => ({
    type: DomainDocumentInstancesActionTypes.REMOVE_VARIABLE,
    documentInstance,
    variableId,
  }),
  updateDocumentInstanceValue: (
    documentInstanceId: IdentityIdentifier,
    documentInstanceValue: EditorValue,
    forceVariableDataRefetch: boolean,
    successCallback?: (documentInstance: FarteDocumentInstance) => void,
  ) => ({
    type: DomainDocumentInstancesActionTypes.UPDATE_VALUE,
    documentInstanceId,
    documentInstanceValue,
    forceVariableDataRefetch,
    successCallback,
  }),
  updateDocumentInstanceWithVariableDataRefetch: (
    id: number,
    documentInstance: Partial<DocumentInstance>,
  ) => ({
    type: DomainDocumentInstancesActionTypes.UPDATE_DOCUMENT_WITH_VARIABLEDATA_REFETCH,
    id,
    documentInstance,
  }),
  lockDocument: (documentId: number, locked: boolean, refreshDocumentLocked?: boolean) => ({
    type: DomainDocumentInstancesActionTypes.LOCK_DOCUMENT,
    documentId,
    locked,
    refreshDocumentLocked,
  }),
  renewPrescription: (documentId: number) => ({
    type: DomainDocumentInstancesActionTypes.RENEW_PRESCRIPTION,
    documentId,
  }),
  lockMultipleDocuments: (documentIds: Array<number>, locked: boolean) => ({
    type: DomainDocumentInstancesActionTypes.LOCK_MULTIPLE_DOCUMENTS,
    documentIds,
    locked,
  }),
}
